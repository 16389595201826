<template>
	<div class="wrap">
		<div class="page_title"><b>租户管理</b></div>
		<div class="main public_box">
			<tenant-admin-hl></tenant-admin-hl>
		</div>
	</div>
</template>

<script> 
// @ is an alias to /src   
import MenuAdminHl from '@/package/tenantAdminHl/index.vue'
import TenantAdminHl from "@/package/tenantAdminHl/index.vue";
export default {
	name: 'tenantAdmin',
	components: {
    TenantAdminHl,
		MenuAdminHl
	},
	data(){
		return{
			loading:false,
		}
	},
	methods:{
		
	},
	mounted() {  // 

	}
}
</script>
<style scoped>
	.main{
		margin-top: 0.2rem;
	}
</style>
