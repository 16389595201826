<template>
  <div class="app-container">
    <!--菜单数据-->
    <div class="mb8">
      <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
      <!-- <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete">删除</el-button> -->
    </div>
    <el-table
        v-loading="loading"
        :data="packageOptions"
        row-key="id"
        :highlight-current-row=true>
      <el-table-column prop="id" label="编号" width="160"></el-table-column>
      <el-table-column class-name="tree_left" prop="name" label="名称" :show-overflow-tooltip="true"
                       width="160">
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          width="160"
          class-name="small-padding fixed-width"
      >
        <template slot-scope="scope" v-if="scope.row.id !== -1">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-view"
              @click="handleShow(scope.row)"
          >查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加或修改菜单配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="680px" append-to-body @opened="handleOpen">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="业务线" prop="businessCodes">
          <el-select v-model="form.businessCodes" clearable placeholder="请选择" :disabled="this.disabled">
            <el-option
                v-for="item in businessOptions"
                :key="item.businessCode"
                :label="item.businessName"
                :value="item.businessCode"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="子系统名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入子系统名称" :disabled="this.disabled"/>
        </el-form-item>
        <el-form-item label="子系统菜单" prop="menuIds">
          <el-tree
              class="tree-border"
              :data="menuOptions"
              show-checkbox
              ref="menuTree"
              node-key="menuId"
              empty-text="加载中，请稍候"
              :props="defaultProps">
          </el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" v-show="this.show">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
// import the component
import Treeselect from '@riophae/vue-treeselect'

export default {
  name: "tenantPackageAdminHl",
  components: {Treeselect},
  data() {
    return {
      // 遮罩层
      loading: false,
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 子系统选项
      packageOptions: [],
      // 业务线选项
      businessOptions: [],
      // 菜单树选项
      menuOptions: [],
      // 回显选中
      menuChecked: [],

      show: true,

      disabled: false,

      defaultProps: {
        children: "children",
        label: "menuName"
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        name: [
          {required: true, message: "子系统名称不能为空", trigger: "blur"}
        ],
        businessCode: [
          {required: true, message: "管理员电话不能为空", trigger: "blur"}
        ]
      }
    };
  },
  watch: {},
  created() {
    this.getPackageAll();
    this.getMenuTreeSelect();
    this.getBusinessAll();
  },
  methods: {
    // 选择图标
    selected(name) {
      this.form.icon = name;
    },

    getPackageAll() {
      this.instance.get('/upms/api/tenant/package/getPackageAll').then(res => {
        this.packageOptions = res.data.data;
      });
    },

    getBusinessAll() {
      this.instance.get('/upms/api/business/getAllBusinessCode').then(res => {
        this.businessOptions = res.data.data;
      });
    },

    /** 查询菜单树结构 */
    getMenuTreeSelect() {
      this.instance.get('/upms/api/menu/getMenuTreeByTenantId', {}).then(res => {
        if (res.data.code === 200) {
          this.menuOptions = res.data.data;
        }
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        this.form.menuIds = this.getMenuAllCheckedKeys();
        this.form.status = 0;
        if (valid) {
          this.instance.post('/upms/api/tenant/package/add', this.form).then(res => {
            if (res.data.code === 200) {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            } else {
              this.$modal.msgError(res.data.message);
            }
          });
        }
      });
    },
    getMenuAllCheckedKeys() {
      // 目前被选中的菜单节点
      let checkedKeys = this.$refs.menu.getCheckedKeys();
      // 半选中的菜单节点
      let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys.join(",");
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        name: undefined,
        businessCode: undefined,
        menuIds: undefined
      };
      this.menuChecked = undefined;
      this.resetForm("form");
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.menuChecked = undefined;
      this.open = true;
      this.title = "添加子系统";
      this.disabled = false;
    },
    /** 修改按钮操作 */
    handleShow(row) {
      this.reset();
      this.form = row;
      this.menuChecked = row.menuIds.split(",");
      this.open = true;
      this.title = "查看";
      this.show = false;
      this.disabled = true;
    },

    handleOpen() {
      if (this.menuChecked !== undefined) {
        this.$refs.menuTree.setCheckedKeys(this.menuChecked)
      } else {
        this.$refs.menuTree.setCheckedKeys([])
      }
    }

  }
};
</script>