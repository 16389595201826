<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="90px">
      <el-form-item label="租户名称:" prop="tenantName">
        <el-input
            v-model="queryParams.tenantName"
            placeholder="请输入租户名称"
            clearable
            class="width_170"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="管理员账号:" prop="userName">
        <el-input
            v-model="queryParams.userName"
            placeholder="请输入手机号码"
            clearable
            class="width_170"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <!--菜单数据-->
    <div class="mb8">
      <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
      <!-- <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete">删除</el-button> -->
    </div>
    <el-table
        v-if="refreshTable"
        v-loading="loading"
        :data="tenantList"
        row-key="id"
        :default-expand-all = false
        :highlight-current-row = true
        :tree-props="{children: 'subTenantVoList', hasChildren: 'hasChildren'}">
      <el-table-column prop="id" label="租户编号" width="160"></el-table-column>
      <el-table-column class-name="tree_left" prop="tenantName" label="租户名称" :show-overflow-tooltip="true"
                       width="160">
      </el-table-column>
      <el-table-column prop="userName" label="管理员账号" width="160"></el-table-column>
      <el-table-column prop="userEmail" label="管理员邮箱" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          width="160"
          class-name="small-padding fixed-width"
      >
        <template slot-scope="scope" v-if="scope.row.id !== -1">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-plus"
              @click="handleAdd(scope.row)"
          >添加
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
          >修改
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNo"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改菜单配置对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="680px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row>
          <el-col :span="24">
            <el-form-item label="上级租户" prop="parentTenantId">
              <treeselect
                  v-model="form.parentTenantId"
                  :options="tenantOptions"
                  :normalizer="normalizer"
                  :show-count="false"
                  placeholder="选择上级租户"
                  :disabled=this.disabled
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="租户名称" prop="tenantName">
              <el-input v-model="form.tenantName" placeholder="请输入租户名称"/>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="管理员账号" prop="userName">
              <el-input v-model="form.userName" placeholder="请输入租户名称"/>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="手机号码" prop="userName">
              <el-input v-model="form.userPhone" placeholder="请输入手机号码"/>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="邮箱地址" prop="userName">
              <el-input v-model="form.userEmail" placeholder="请输入邮箱地址"/>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="开通业务线" prop="parentId">
              <el-select v-model="form.tenantPackage" clearable placeholder="请选择">
                <el-option
                    v-for="item in packageOptions"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
// import the component
import Treeselect from '@riophae/vue-treeselect'

export default {
  name: "tenantAdminHl",
  components: {Treeselect},
  data() {
    return {
      // 遮罩层
      loading: false,
      // 非单个禁用
      single: true,
      // 重新渲染表格状态
      refreshTable: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 菜单表格数据
      tenantList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 岗位选项
      packageOptions: [],
      // 租户树选项
      tenantOptions: [],
      // 子租户
      subTenantVoList: [],
      // 表单参数
      form: {},
      disabled: false,
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        userName: undefined,
        tenantName: undefined
      },
      // 表单校验
      rules: {
        tenantName: [
          {required: true, message: "租户名称不能为空", trigger: "blur"}
        ],
        userPhone: [
          {required: true, message: "管理员电话不能为空", trigger: "blur"}
        ],
        userName: [
          {required: true, message: "管理员账号不能为空", trigger: "blur"}
        ],
        userEmail: [
          {required: true, message: "管理员邮箱不能为空", trigger: "blur"}
        ],
        tenantPackage: [
          {required: true, message: "业务线不能为空", trigger: "blur"}
        ]
      }
    };
  },
  watch: {},
  created() {
    this.getList();
    this.getPackageAll();
    this.getTreeSelect();
  },
  methods: {
    // 选择图标
    selected(name) {
      this.form.icon = name;
    },
    /** 查询菜单列表 */
    getList() {
      this.loading = true;
      this.tableData = [];//  清空table的值
      let queryParams = JSON.parse(JSON.stringify(this.queryParams));
      let json = {
        condition: queryParams,
        pageNo: queryParams.pageNo,
        pageSize: queryParams.pageSize
      }
      // this.instance.get('/upms/api/menu/getMenuTree',{}).then(res=>{
      this.instance.post('/upms/api/tenant/paging', json).then(res => {
        this.loading = false;
        this.tenantList = res.data.data.list;
        this.total = parseInt(res.data.data.total);
      });
    },

    getPackageAll() {
      this.instance.get('/upms/api/tenant/package/getPackageAll').then(res => {
        this.packageOptions = res.data.data;
      });
    },

    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.tenantName,
        children: node.subTenantVoList
      };
    },

    getTreeSelect() {
      let json = {
        condition: {},
        pageNo: this.queryParams.pageNo,
        pageSize: this.queryParams.pageSize
      }
      // this.instance.get('/upms/api/menu/getMenuTree',{}).then(res=>{
      this.instance.post('/upms/api/tenant/paging', json).then(res => {
        this.loading = false;
        this.tenantOptions = res.data.data.list;
      });
    },

    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.form.id != undefined) {
            this.instance.post('/upms/api/tenant/edit', this.form).then(res => {
              if (res.data.code === 200) {
                this.$modal.msgSuccess("修改成功");
                this.open = false;
                this.getList();
              } else {
                this.$modal.msgError(res.data.message);
              }
            });
          } else {
            this.instance.post('/upms/api/tenant/add', this.form).then(res => {
              if (res.data.code === 200) {
                this.$modal.msgSuccess("新增成功");
                this.open = false;
                this.getList();
              } else {
                this.$modal.msgError(res.data.message);
              }
            });
          }
        }
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        parentTenantId: undefined,
        tenantName: undefined,
        userName: undefined,
        userEmail: undefined,
        userPhone: undefined,
        tenantPackage: undefined
      };
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {

      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.queryParams.menuId = undefined;
      this.$refs.tree.setCurrentKey(null);
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset();
      this.getPackageAll();
      this.getTreeSelect();
      this.open = true;
      if (row != null && row.id) {
        this.form.parentTenantId = row.id;
        this.disabled = true;
      } else {
        this.form.parentTenantId = undefined;
        this.disabled = false;
      }
      this.title = "添加租户";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.getPackageAll();
      this.getTreeSelect();
      this.form = row;
      this.form.parentTenantId = row.parentTenantId
      this.disabled = false;
      this.open = true;
      this.title = "修改租户";
    }

  }
};
</script>