<template>
  <div class="wrap">
	<login-hl></login-hl>
  </div>
</template>
<script>
	import loginHl from '@/package/loginHl/index.vue'
	export default {
		name: 'Login',
		components: {
			loginHl
		},
		data(){
			return{
				
			}
		},
		watch: {
			
		},
		methods:{
			
		},
		mounted() {
			// console.log(this.$route.name)
			// this.refreshCode()
		}
	}
</script>
<style scoped>
	.wrap{
		width: 100%;
		height: 100%;
	}
</style>