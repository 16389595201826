<template>
    <div>
        <el-dialog width="80%" :title="ruleEditor.title" :modal-append-to-body="false" :visible="ruleEditor.visible" :before-close="handleClose">
            <div>
                <el-form ref="ruleForm" :model="ruleDetail" label-width="120px" :inline="true" class="demo-form-inline">
                    <el-form-item label="规则编号：">
                        <el-input v-model="ruleDetail.code" class="width_180" disabled />
                    </el-form-item>
                    <el-form-item label="规则名称：" :rules="{ required: true, message: '规则名称不能为空', trigger: 'blur' }">
                        <el-input v-model="ruleDetail.name" class="width_180" placeholder="请输入规则名称" />
                    </el-form-item>
                    <el-form-item label="规则分类">
                        <el-input v-model="ruleDetail.scene" class="width_180" placeholder="请输入规则类别" />
                    </el-form-item>
                    <el-form-item label="规则描述：">
                        <el-input type="textarea" v-model="ruleDetail.desc" class="width_350" placeholder="请输入规则描述" />
                    </el-form-item>
                    <el-divider></el-divider>
                </el-form>
                <div>
                    <ejs-querybuilder width="100%" id="querybuilder" ref="exprEditor" :showButtons="showButtons" :columns="features" :dataSource="features"> </ejs-querybuilder>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="ruleEditor.visible = false">取 消</el-button>
                <el-button type="primary" @click="ruleUpload">确 定</el-button>
            </span>
        </el-dialog>
        <div class="wrap" v-loading="loading">
            <div class="page_title"><b>规则管理</b></div>

            <div class="table_box public_box">
                <div class="button_container">
                    <el-button type="primary" @click="editRules('')">新增</el-button>
                </div>
                <el-table :data="ruleDataFrame.data" style="width: 100%">
                    <template v-for="column in ruleTableOptions.columns">
                        <template v-if="column.prop == 'operators'">
                            <el-table-column :label="column.label" :prop="column.prop" :key="column.code">
                                <template scope="scope">
                                    <el-button type="primary" @click="editRules(scope.row.ruleCode)">修改</el-button>
                                </template>
                            </el-table-column>
                        </template>
                        <template v-else-if="column.prop == 'status'">
                            <el-table-column :label="column.label" :prop="column.prop" :key="column.code">
                                <template slot-scope="scope">
                                    <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949" inactive-value="10" active-value="0" @change="onStatusChange(scope.row)"> </el-switch>
                                </template>
                            </el-table-column>
                        </template>
                        <template v-else-if="column.prop == 'ruleJson'">
                            <el-table-column :label="column.label" :prop="column.prop" :key="column.code" />
                        </template>
                        <template v-else>
                            <el-table-column :label="column.label" :prop="column.prop" :key="column.code" />
                        </template>
                    </template>
                </el-table>
                <el-pagination style="margin-top: 0.2rem; text-align: right" layout="total, prev, pager, next" :page-size="10" :total="ruleDataFrame.total" :current-page="ruleDataFrame.current" @current-change="fetchRuleDataFrame"> </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import * as Options from './options'
import { createElement, getComponent } from '@syncfusion/ej2-base'
import { DropDownList } from '@syncfusion/ej2-dropdowns'
let inOperators = ['in', 'notin']

export default {
    name: 'RuleAdmin',
    components: {},
    data() {
        return {
            loading: false,
            ruleDataFrame: {
                data: [],
                total: 0
            },
            ruleDetail: {},
            features: [],
            ...Options,
            ruleEditor: {
                visible: false,
                title: '新增规则'
            },
            dataSource: Options.employeeData,
            values: ['Mr.', 'Mrs.'],
            showButtons: { ruleDelete: true, groupInsert: true, groupDelete: true },
            paymentTemplate: {
                create: (args) => {
                    console.log(args)
                    return createElement('input', { attrs: { type: 'text' } })
                },
                destroy: (args) => {
                    let multiselect = getComponent(document.getElementById(args.elementId), 'multiselect')
                    if (multiselect) multiselect.destroy()
                    let dropdownlist = getComponent(document.getElementById(args.elementId), 'dropdownlist')
                    if (dropdownlist) dropdownlist.destroy()
                },
                write: (args) => {
                    if (inOperators.indexOf(args.operator) > -1) {
                        let multiSelectObj = new MultiSelect({
                            dataSource: ds,
                            value: args.values,
                            mode: 'CheckBox',
                            placeholder: 'Select Transaction',
                            change: (e) => {
                                this.$refs.exprEditor.$el.ej2_instances[0].notifyChange(e.value, e.element)
                            }
                        })
                        multiSelectObj.appendTo('#' + args.elements.id)
                    } else {
                        let dropDownObj = new DropDownList({
                            dataSource: ds,
                            value: args.values,
                            change: (e) => {
                                this.$refs.exprEditor.$el.ej2_instances[0].notifyChange(e.itemData.value, e.element)
                            }
                        })
                        dropDownObj.appendTo('#' + args.elements.id)
                    }
                }
            }
        }
    },
    methods: {
        onStatusChange: function (row) {
            this.$data.loading = true
            this.instance
                .post('/risk/api/rule/definition/edit', { code: row.ruleCode, status: row.status })
                .then(({ data }) => {
                    this.$data.loading = false
                    const operate = row.status == 0 ? '启用' : '停用'
                    if (data.code == 200) {
                        this.$message({ message: '规则' + row.ruleName + '已' + operate, type: 'success' })
                    } else {
                        this.$message.error('规则' + row.ruleName + operate + '失败！')
                    }
                })
                .catch((_) => (this.$data.loading = false))
        },
        editRules: function (ruleCode) {
            this.$data.ruleEditor.visible = true
            this.$data.ruleDetail = {}
            this.$refs.exprEditor && this.$refs.exprEditor.ej2Instances.reset()
            if (ruleCode) {
                this.$data.loading = true
                this.$data.ruleEditor.title = '编辑规则'
                this.instance
                    .post('/risk/api/rule/definition/' + ruleCode)
                    .then(({ data }) => {
                        this.$data.loading = false
                        const ruleDetail = data.data
                        this.$data.ruleDetail = {
                            code: ruleDetail.code,
                            desc: ruleDetail.desc,
                            name: ruleDetail.name,
                            scene: ruleDetail.scene
                        }
                        console.log(ruleDetail)
                        this.$refs.exprEditor.ej2Instances.setRulesFromSql(ruleDetail.formula)
                    })
                    .catch((error) => {
                        this.$data.loading = false
                        this.$message.error('加载规则详情失败，网络错误！')
                    })
            } else {
                this.$data.ruleEditor.title = '新增规则'
                this.$data.ruleDetail = {}
                this.$refs.exprEditor.ej2Instances.reset()
                this.$refs.exprEditor.ej2Instances.setRules({ condition: 'and', rules: [{}] })
            }
        },
        ruleUpload: function () {
            this.$data.loading = true
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    const rule = {
                        ...this.$data.ruleDetail,
                        output: {
                            failFeatureValue: 'test1',
                            featureCode: 'FEATURE1626891799006163044',
                            featureValue: 'test'
                        }
                    }
                    const sqlExpr = this.$refs.exprEditor.ej2Instances.getSqlFromRules()
                    rule.formula = sqlExpr
                    console.log(sqlExpr)
                    let sign = this.ruleDetail.code ? 'edit' : 'config'
                    this.instance
                        .post('/risk/api/rule/definition/' + sign, rule)
                        .then(({ data }) => {
                            this.$data.loading = false
                            if (data.code == 200) {
                                this.$message({ message: '规则配置成功', type: 'success' })
                                this.ruleEditor.visible = false
                                this.fetchRuleDataFrame(1)
                            } else {
                                this.$message({ message: data.message, type: 'error' })
                            }
                        })
                        .catch((err) => {
                            this.$data.loading = false
                            this.$message({ message: '保存失败，网络错误', type: 'error' })
                        })
                } else {
                    this.$message({ message: '规则配置错误，无法提交，请重新填写！', type: 'error' })
                }
            })
        },

        handleClose: function () {
            this.ruleEditor.visible = false
        },
        handleRuleChange: function () {
            console.log(this.$refs.exprEditor.ej2Instances.getSqlFromRules())
        },
        fetchRuleDataFrame: function (currentPage) {
            this.$data.loading = true
            this.instance.post('/risk/api/rule/definition/paging', { pageNo: currentPage, pageSize: 10 }).then(({ data }) => {
                this.$data.loading = false
                console.log(data)
                this.$data.ruleDataFrame = {
                    data: data.data.list,
                    total: parseInt(data.data.total),
                    current: parseInt(data.data.current)
                }
            })
        }
    },
    mounted() {
        //
        this.baseURL = this.$store.getters.baseURL
        this.fetchRuleDataFrame(1)
        this.instance.post('/risk/api/feature/definition/paging', { pageNo: 1, pageSize: 100 }).then(({ data }) => {
            const list = data.data.list
            const columns = []
            const values = list.map((item) => item.featureName)
            for (var i = 0; i < list.length; i++) {
                const feature = list[i]
                columns.push({
                    field: feature.featureCode,
                    label: feature.featureName,
                    type: Options.getDataType(feature.dataType)
                    //template: this.paymentTemplate,
                    //values: values.filter(v => v != feature.featureName)
                })
            }
            this.$data.features = columns
            //this.$refs.querybuilder.ej2Instances.setRules(this.$data.importRules);
        })
    }
}
</script>


<style scoped>
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../../node_modules/@syncfusion/ej2-vue-querybuilder/styles/material.css';
.e-query-builder {
    margin: 0 auto;
}
.e-qb-button {
    margin: 2% 1% 0 15%;
}

.e-qbr-button {
    margin: 2% 1% 0 1%;
}

#datatable {
    margin-left: 15%;
    margin-top: 2%;
}

#datatable.e-table {
    border: solid 1px #e0e0e0;
    border-collapse: collapse;
    font-family: Roboto;
}

#datatable.e-table td,
#datatable.e-table th {
    border: solid #e0e0e0;
    border-width: 1px 0 0;
    display: table-cell;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    padding: 8px 21px;
    vertical-align: middle;
    white-space: nowrap;
    width: auto;
}
.table_box {
    margin-bottom: 12px;
    margin-top: 10px;
}
.button_container {
    width: 100%;
    text-align: right;
    padding-bottom: 20px;
    padding-right: 40px;
}
</style>
