<template>
  <div class="wrap">
	<div class="login_box">
	    <p class="login_title">登录</p>
		<el-form class="login_form" @keyup.enter.native="handleLogin" ref="form" :model="form" >
		  <el-form-item>
		    <el-input v-model="form.username"  size="medium" placeholder="请输入用户名"></el-input>
		  </el-form-item>
		  <el-form-item >
		    <el-input v-model="form.password"  size="medium" placeholder="请输入密码" type="password"></el-input>
		  </el-form-item>
		  <!-- <el-form-item  class="qrcode" v-if="isVerification">
		   <el-input class="qrcode_input" v-model="form.smsCode" size="medium" placeholder="请输入验证码"></el-input>
			<el-button class="code_btn" :class="{active:codeTime>0}" @click="sendCode" >{{isClickCode?'重新发送('+codeTime+')':'获取验证码'}}</el-button>
		  </el-form-item> -->
		  <el-form-item >
		    <el-button class="submit_btn" size="medium" :disabled="isDisabled" @click="handleLogin">登 录</el-button>
		  </el-form-item>
		</el-form>
	</div>
	<div class="version">Copyright @2018</div>
  </div>
</template>
<script>
	import {setTimeToken,intervalRefreshToken} from '../../utils/auth.js'
	export default {
		name: 'Login',
		components: {
		},
		data(){
			return{
				codeTime:0,   //验证码时间
				redirect:'',
				isVerification:true,
				isDisabled:false,
				identifyCode:'',
				identifyCodes:[1,2,3,4,5,6,7,8,9,0],
				passwordShow:false,    //密码是否显示
				codeActive:false, //发送验证码按钮颜色
				isClickCode:false, //是否点击过发送验证码
				form:{
					//   smsCode:'202112',
					username:"",       //用户名
					password:""        //密码
				}
			}
		},
		watch: {
			$route: {
			handler: function(route) {
				
				this.redirect = route.query && route.query.redirect
			},
			immediate: true
			}
		},
		methods:{
			//发送验证码
			sendCode(){
				var _this=this;
				if(!this.form.username){
					_this.$message.error('抱歉，请输入用户名');
					return false
				}
				if(!_this.form.password){
					_this.$message.error('抱歉,请输入密码!');
					return false;
				}
				if(this.codeTime<=0){
					_this.codeTime=60;
					this.isClickCode=true;
					this.instance.post('/vehicle/damage/sendShortMessage',this.form).then(res=>{
						if(res.data.code==1){
							var timer = setInterval(function () {
								_this.codeTime--;
								if (_this.codeTime <= 0) {
									_this.isClickCode=false;
									_this.codeActive=true;
									clearInterval(timer);
								}
							}, 1000); 
						}else{
							_this.codeTime=0;
							_this.codeActive=false;
							_this.isClickCode=false;
							_this.$message.error(res.data.message);
						}
					});
					
				}
			},
			// 图形验证码 123
			randomNum(min,max){
				return Math.floor(Math.random()*(max-min)+min)
			},
			refreshCode(){
				this.identifyCode="";
				this.makeCode(this.identifyCodes,4);
				// console.log(this.identifyCode);
			},
			makeCode(o,l){
				for(let i=0;i<l;i++){
					this.identifyCode+=this.identifyCodes[
						this.randomNum(0,this.identifyCodes.length)
					]
				} 
			},
			//点击提交按钮的方法 
			handleLogin(){
				var _this=this;
				if(!_this.form.username){
					_this.$message.error('抱歉,请输入用户名!');
					return false;
				}
				if(!_this.form.password){
					_this.$message.error('抱歉,请输入密码!');
					return false;
				}
				//   if(!_this.form.smsCode){
				//       _this.$message.error('抱歉,请输入验证码!');
				//       return false;
				//   }
				this.isDisabled=true;
				this.instance.post('/login/userLogin',this.form).then(res=>{
					var data=res.data;
					this.isDisabled=false;
					if(data.code==200){
						setTimeToken(data.data.tokenVo)
						localStorage.setItem("AUTH_TOKEN",data.data.tokenVo.accessToken);
						localStorage.setItem("username",data.data.username);
						localStorage.setItem("userInfo",JSON.stringify(data.data));
						// this.$router.replace({path:'/Home'}); 
						let _path='/caseInquiry/index';
						this.$router.push({ path: this.redirect || _path })
					}else{
						this.$message.error(data.message)
					}
				}).catch(res=>{
					this.isDisabled=false;
				});
			}
		},
		mounted() {
			// console.log(this.$route.name)
			// this.refreshCode()
		}
	}
</script>
<style scoped>
	.login_box .submit_btn{
		width: 100%;
		background: #22BDBD;
		border-color:#22BDBD;
		color: #fff;
	}
	.login_box .el-button:focus,.login_box .el-button:hover,.code_btn:hover{
		background: #81EFF0;
		border-color: #81EFF0;
		color: #4C6161;
	}
	.verify-box{
		margin-left:0.2rem;
	}
	.code_btn{
		width: 125px;
		margin-left:15px;
		background: #22BDBD;
		color: #fff;
		text-align: center;
		border-radius: 4px;
		cursor: pointer;
		border: none;
	}
	.code_btn.active{
		color:#aaa;
		background: #EEEEEE;
	}
	.qrcode_input{
		width:150px;
	}
	.qrcode /deep/.el-form-item__content{
		display: flex;
		width: 100%;
	}
	.wrap{
	    height:100%;
	    width:100%;
	    position: relative;
	    background-image: url(../../../public/images/login_bg.jpg) ;
		background-repeat:no-repeat;
		background-color: #fff;
	    background-size: cover;
	    background-position: center;
	}
	.version{
		position: absolute;
		bottom:0.5rem;
		right:20%;
		z-index: 50;
		color: #858688;
	}
	.login_box{
	   /* width:4.2rem;
	    height:5.9rem; */
		width:350px;
		height:320px;
	    position: absolute;
	    top:50%;
	    right:12%;
		transform: translate(-12%,-50%);
	    z-index: 50;
		background: url(../../../public/images/login_form.png) no-repeat;
		background-size: 100% 100%;
		border-radius: 0.1rem;
		padding: 40px 30px;
		/* color: #fff; */
	}
	.login_box .el-form-item__label{
		color: #fff !important;
	}
	.login_box .el-input__inner{
		background-color: rgba(255,255,255,0.1);
		/* color: #fff; */
	}
	.login_title{
		font-size: 0.3rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #fff;
		margin-bottom:40px;
		text-align: center;
	}
	.login_form{
	    width:100%;
	    margin: 0 auto 0;

	}
	.login_form .el-input__inner{
		color: #CFDDE5;
	}
	.login_btn button{
	    border: none;
	    background: #003B8F;
	}
	.login_btn button:hover{
	    background: #01265A;
	}
	.user,.password{
	    width:100%;
	    /* height:0.48rem; */
	    display: flex;
	    align-items: center;
	    flex-grow: 1;
	    border-bottom:1px solid #DFE5EC;
	    padding:0 0.16rem;
	}
	.password{
	    justify-content: space-between;
	}
	.user span,.password span{
	    width:0.14rem;
	}
	.password_box{
	    display: flex;
	    align-items: center;
	}
	
	.isshowpass{
	    cursor: pointer;
	    width:0.22rem;
	}
	.login_btn{
	    margin-top:30px;
	}
	.login_btn button{
	    width:100%;
	}
	
</style>