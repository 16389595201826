<template>
    <div class="app-container">
        <!--组织架构数据-->
      <!-- <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="110px">
        <el-form-item label="组织架构名称：" prop="deptName">
          <el-input
            v-model="queryParams.deptName"
            placeholder="请输入组织架构名称"
            clearable
            class="width_170"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="状态：" prop="status">
          <el-select  v-model="queryParams.status"  placeholder="组织架构状态"  clearable  class="width_170">
            <el-option  label="正常"  value="0"/>
            <el-option  label="停用"  value="1"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form> -->
      <div class="mb8">
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
        <el-button
          type="info"
          plain
          icon="el-icon-sort"
          size="mini"
          @click="toggleExpandAll"
        >展开/折叠</el-button>
        <!-- <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete">删除</el-button> -->
      </div>
      <el-table 
        v-if="refreshTable"
        v-loading="loading"
        :data="deptList"
        row-key="deptId"
        :default-expand-all="isExpandAll"
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column class-name="tree_left" align="left" prop="deptName" label="组织架构名称"></el-table-column>
        <el-table-column prop="orderNum" label="排序"></el-table-column>
        <el-table-column prop="status" label="状态">
          <template slot-scope="scope">
            <el-tag>{{scope.row.status==0?'正常':'停用'}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="createTime">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.createTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          width="160"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope" v-if="scope.row.userId !== -1">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
            >修改</el-button>
            <el-button
              size="mini"
              type="text"
              icon="el-icon-plus"
              @click="handleAdd(scope.row)"
            >新增</el-button>
            <!-- <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
            >删除</el-button> -->
            <!-- <el-dropdown size="mini" @command="(command) => handleCommand(command, scope.row)">
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right">更多</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="handleResetPwd" icon="el-icon-key"
                  >重置密码</el-dropdown-item>
                <el-dropdown-item command="handleAuthRole" icon="el-icon-circle-check"
                  >分配组织架构</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNo"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
  
      <!-- 添加或修改组织架构配置对话框 -->
      <el-dialog :title="title" :visible.sync="open" width="680px" append-to-body>
        <el-form ref="form" :model="form" :rules="rules" label-width="110px">
        <el-row v-if="form.parentId!==0">
          <el-col :span="24">
            <el-form-item label="上级组织架构" prop="parentId">
              <treeselect v-model="form.parentId" :options="deptOptions" :normalizer="normalizer" placeholder="选择上级组织架构" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="组织架构名称" prop="deptName">
              <el-input v-model="form.deptName" placeholder="请输入组织架构名称" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="显示排序" prop="orderNum">
              <el-input-number v-model="form.orderNum" controls-position="right" :min="0" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="负责人" prop="leader">
              <el-input v-model="form.leader" placeholder="请输入负责人" maxlength="20" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="phone">
              <el-input v-model="form.phone" placeholder="请输入联系电话" maxlength="11" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="form.email" placeholder="请输入邮箱" maxlength="50" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="组织架构状态">
              <el-select  v-model="form.status"  placeholder="组织架构状态"  clearable >
                <el-option  label="正常"  value="0"/>
                <el-option  label="停用"  value="1"/>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="cancel">取 消</el-button>
        </div>
      </el-dialog>
    </div>
</template>
  
  <script>
//   import { listUser, getUser, delUser, addUser, updateUser, resetUserPwd, changeUserStatus, deptTreeSelect } from "@/api/system/user";
  import { getToken } from "@/utils/auth";
  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  export default {
    name: "deptAdminHl",
    dicts: ['sys_normal_disable', 'sys_user_sex'],
    components: { Treeselect },
    data() {
      return {
        // 遮罩层  
        loading: false,
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 重新渲染表格状态
        refreshTable: true,
        // 非多个禁用
        multiple: true,
        // 显示搜索条件
        showSearch: true,
        // 总条数
        total: 0,
        // 组织架构表格数据
        deptList: null,
        // 弹出层标题
        title: "",
        // 组织架构树选项
        deptOptions: undefined,
        // 是否显示弹出层
        open: false,
        // 岗位选项
        postOptions: [],
        // 组织架构树选项
        menuOptions: [],
        // 组织架构选项
        roleOptions: [],
        dictOption:[],
        // 是否展开，默认全部折叠
       isExpandAll: false,
        // 表单参数
        form: {orderNum: 99},
        // 查询参数
        queryParams: {
          pageNo: 1,
          pageSize: 10,
          deptName: undefined,
          status: undefined
        },
        // 表单校验
        rules: {
          // parentId: [
          //   { required: true, message: "上级组织架构不能为空", trigger: "blur" }
          // ],
          deptName: [
            { required: true, message: "组织架构名称不能为空", trigger: "blur" }
          ],
          orderNum: [
            { required: true, message: "显示排序不能为空", trigger: "blur" }
          ],
          email: [
            {
              type: "email",
              message: "请输入正确的邮箱地址",
              trigger: ["blur", "change"]
            }
          ],
          phone: [
            {
              pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
              message: "请输入正确的手机号码",
              trigger: "blur"
            }
          ]
        }
      };
    },
    watch: {
    },
    created() {
      this.getList();
    //   this.getConfigKey("sys.user.initPassword").then(response => {
    //     this.initPassword = response.msg;
    //   });
    },
    methods: {
      // 选择图标
      selected(name) {
        this.form.icon = name;
      },
      /** 查询组织架构列表 */
      getList() {
        this.loading=true;
        this.deptList=[];//  清空table的值
        var queryParams=JSON.parse(JSON.stringify(this.queryParams));
        let json={
          condition:queryParams,
          pageNo:queryParams.pageNo,
          pageSize:queryParams.pageSize
        }
        this.instance.get('/upms/api/dept/getDeptTree',{}).then(res=>{
            // console.log(res); 
            this.loading=false;
            this.deptList=res.data.data;
            // this.total=parseInt(res.data.data.total);
            let response=res.data.data;
            function addLabel(items){
              if(items){
                items.forEach((val)=>{
                  val.label=val.deptName;
                  val.id=val.deptId;
                  if (val.children == null) {
                    delete val.children
                  }
                  val.children&&addLabel(val.children)
                })
              }else{
                return false
              }
            }
            addLabel(response)
            this.deptOptions = response;
        });
      },
      /** 转换组织架构数据结构 */
      normalizer(node) {
        if (node.children && !node.children.length) {
          delete node.children;
        }
        return {
          id: node.deptId,
          label: node.deptName,
          children: node.children
        };
      },
      /** 查询组织架构下拉树结构 */
      getTreeselect() {
        listMenu().then(response => {
          this.menuOptions = [];
          const menu = { menuId: 0, menuName: '主类目', children: [] };
          menu.children = this.handleTree(response.data, "menuId");
          this.menuOptions.push(menu);
        });
      },
      // 组织架构状态修改
      handleStatusChange(row) {
        let text = row.status === "0" ? "启用" : "停用";
        this.$modal.confirm('确认要"' + text + '""' + row.userName + '"组织架构吗？').then(() => {
          let json={
            userId:row.userId,
            status:row.status
          }
          this.instance.post('/upms/api/user/edit',json).then(res=>{
            
          });
          this.$modal.msgSuccess(text + "成功");
        }).catch(function() {
          row.status = row.status === "0" ? "1" : "0";
        });
      },
      /** 展开/折叠操作 */
      toggleExpandAll() {
        this.refreshTable = false;
        this.isExpandAll = !this.isExpandAll;
        this.$nextTick(() => {
          this.refreshTable = true;
        });
      },
      // 取消按钮
      cancel() {
        this.open = false;
        this.reset();
      },
       // 取消按钮（数据权限）
      cancelDataScope() {
        this.openDataScope = false;
        this.reset();
      },
       /** 选择组织架构权限范围触发 */
      dataScopeSelectChange(value) {
        if(value !== '2') {
          this.$refs.dept.setCheckedKeys([]);
        }
      },
      /** 提交按钮（数据权限） */
      submitDataScope() {
        if (this.form.roleId != undefined) {
          this.form.deptIds = this.getDeptAllCheckedKeys();
          dataScope(this.form).then(response => {
            this.$modal.msgSuccess("修改成功");
            this.openDataScope = false;
            this.getList();
          });
        }
      },
      // 表单重置
      reset() {
        this.form = {
          deptId: undefined,
          parentId: undefined,
          deptName: undefined,
          orderNum: 99,
          leader: undefined,
          phone: undefined,
          email: undefined,
          status: "0"
        };
        this.resetForm("form");
      },
      /** 搜索按钮操作 */
      handleQuery() {
        
        this.queryParams.pageNum = 1;
        this.getList();
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.dateRange = [];
        this.resetForm("queryForm");
        this.queryParams.deptId = undefined;
        this.$refs.tree.setCurrentKey(null);
        this.handleQuery();
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map(item => item.userId);
        this.single = selection.length != 1;
        this.multiple = !selection.length;
      },
      // 更多操作触发
      handleCommand(command, row) {
        switch (command) {
          case "handleResetPwd":
            this.handleResetPwd(row);
            break;
          case "handleAuthRole":
            this.handleAuthRole(row);
            break;
          default:
            break;
        }
      },
      /** 新增按钮操作 */
      handleAdd(row) {
        this.reset();
        this.open = true;
        // this.getTreeselect();
        if (row != null && row.deptId) {
          this.form.parentId = row.deptId;
        } 
        // else {
        //   this.form.parentId = 0;
        // }
        this.title = "添加组织架构";
        // getUser().then(response => {
        //   this.postOptions = response.posts;
        //   this.roleOptions = response.roles;
        //   this.open = true;
        //   this.title = "添加组织架构";
        //   this.form.password = this.initPassword;
        // });
      },
      /** 修改按钮操作 */
      handleUpdate(row) {
        this.reset();
        // this.getTreeselect();
        this.instance.get('/upms/api/dept/getDeptById',{params:{deptId:row.deptId}}).then(res=>{
          if(res.data.code==200){
            this.form =res.data.data;
            this.open = true;
            this.title = "修改组织架构";
          }else{
            this.$modal.msgError(res.data.message);
          }
        });
      },
      /** 重置密码按钮操作 */
      handleResetPwd(row) {
        this.$prompt('请输入"' + row.userName + '"的新密码', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          inputPattern: /^.{5,20}$/,
          inputErrorMessage: "组织架构密码长度必须介于 5 和 20 之间"
        }).then(({ value }) => {
            resetUserPwd(row.userId, value).then(response => {
              this.$modal.msgSuccess("修改成功，新密码是：" + value);
            });
          }).catch(() => {});
      },
      /** 分配组织架构操作 */
      handleAuthRole: function(row) {
        const userId = row.userId;
        this.$router.push("/system/user-auth/role/" + userId);
      },
      /** 提交按钮 */
      submitForm() {
        this.$refs["form"].validate(valid => {
          if (valid) {
            if (this.form.deptId != undefined) {
              this.instance.post('/upms/api/dept/edit',this.form).then(res=>{
                if(res.data.code==200){
                  this.$modal.msgSuccess("修改成功");
                  this.open = false;
                  this.getList();
                }else{
                  this.$modal.msgError(res.data.message);
                }
              });
            } else {
              this.instance.post('/upms/api/dept/add',this.form).then(res=>{
                if(res.data.code==200){
                  this.$modal.msgSuccess("新增成功");
                  this.open = false;
                  this.getList();
                }else{
                  this.$modal.msgError(res.data.message);
                }
              });
            }
          }
        });
      },
      /** 删除按钮操作 */
      handleDelete(row) {
        const userIds = row.userId || this.ids;
        this.$modal.confirm('是否确认删除组织架构编号为"' + userIds + '"的数据项？').then(function() {
          return delUser(userIds);
        }).then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        }).catch(() => {});
      },
      /** 导出按钮操作 */
      handleExport() {
        this.download('system/user/export', {
          ...this.queryParams
        }, `user_${new Date().getTime()}.xlsx`)
      },
      /** 导入按钮操作 */
      handleImport() {
        this.upload.title = "组织架构导入";
        this.upload.open = true;
      },
      /** 下载模板操作 */
      importTemplate() {
        this.download('system/user/importTemplate', {
        }, `user_template_${new Date().getTime()}.xlsx`)
      },
      // 文件上传中处理
      handleFileUploadProgress(event, file, fileList) {
        this.upload.isUploading = true;
      },
      // 文件上传成功处理
      handleFileSuccess(response, file, fileList) {
        this.upload.open = false;
        this.upload.isUploading = false;
        this.$refs.upload.clearFiles();
        this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + response.msg + "</div>", "导入结果", { dangerouslyUseHTMLString: true });
        this.getList();
      },
      // 提交上传文件
      submitFileForm() {
        this.$refs.upload.submit();
      }
    }
  };
  </script>