<template>
	<div class="wrap">
		<div class="page_title"><b>组织架构管理</b></div>
		<div class="main public_box">
			<dept-admin-hl></dept-admin-hl>
		</div>
	</div>
</template>

<script> 
// @ is an alias to /src   
import DeptAdminHl from '@/package/deptAdminHl/index.vue'
export default {
	name: 'deptAdmin',
	components: {
		DeptAdminHl
	},
	data(){
		return{
			loading:false,
			menuType:'searchReviewPage'
		}
	},
	methods:{
		
	},
	mounted() {  // 

	}
}
</script>
<style scoped>
	.main{
		margin-top: 0.2rem;
	}
</style>
