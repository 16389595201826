<template>
    <div class="app-container">
        <!--菜单数据-->
        <!-- <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="90px">
        <el-form-item label="菜单名称：" prop="menuName">
          <el-input
            v-model="queryParams.menuName"
            placeholder="请输入菜单名称"
            clearable
            class="width_170"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="状态：" prop="status">
          <el-select  v-model="queryParams.status"  placeholder="菜单状态"  clearable  class="width_170">
            <el-option  label="正常"  value="0"/>
            <el-option  label="停用"  value="1"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form> -->
        <div class="mb8">
            <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
            <el-button type="info" plain icon="el-icon-sort" size="mini" @click="toggleExpandAll" v-if="0">展开/折叠</el-button>
            <!-- <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete">删除</el-button> -->
        </div>
        <el-table v-if="refreshTable" v-loading="loading" :data="menuList" row-key="menuId" max-height="450" :expand-row-keys="expands" :default-expand-all="isExpandAll" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" @cell-click="cellClick">
            <el-table-column class-name="tree_left" prop="menuName" label="菜单名称" :show-overflow-tooltip="true" width="160"></el-table-column>
            <el-table-column prop="icon" label="图标" align="center" width="100">
                <template slot-scope="scope">
                    <i :class="scope.row.icon"></i>
                </template>
            </el-table-column>
            <el-table-column prop="orderNum" label="排序" width="60"></el-table-column>
            <el-table-column prop="perms" label="权限标识" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="component" label="组件路径" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column prop="status" label="状态" width="80">
                <template slot-scope="scope">
                    <el-tag>{{ scope.row.status == 0 ? '正常' : '停用' }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="创建时间" align="center" prop="createTime">
                <template slot-scope="scope">
                    <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="160" class-name="small-padding fixed-width">
                <template slot-scope="scope" v-if="scope.row.userId !== -1">
                    <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)">修改</el-button>
                    <!-- <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
            >删除</el-button> -->
                    <!-- <el-dropdown size="mini" @command="(command) => handleCommand(command, scope.row)">
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right">更多</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="handleResetPwd" icon="el-icon-key"
                  >重置密码</el-dropdown-item>
                <el-dropdown-item command="handleAuthRole" icon="el-icon-circle-check"
                  >分配菜单</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
                </template>
            </el-table-column>
        </el-table>

        <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNo" :limit.sync="queryParams.pageSize" @pagination="getList" />

        <!-- 添加或修改菜单配置对话框 -->
        <el-dialog :title="title" :visible.sync="open" width="680px" append-to-body>
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="上级菜单" prop="parentId">
                            <treeselect v-model="form.parentId" :options="menuOptions" :normalizer="normalizer" :show-count="true" placeholder="选择上级菜单" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="菜单类型" prop="menuType">
                            <el-radio-group v-model="form.menuType">
                                <el-radio label="M">目录</el-radio>
                                <el-radio label="C">菜单</el-radio>
                                <el-radio label="F">按钮</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="form.menuType != 'F' && title == '修改菜单'">
                        <el-form-item label="菜单图标" prop="icon">
                            <!-- <el-popover placement="bottom-start" width="460" trigger="click" @show="$refs['iconSelect'].reset()">
                                <IconSelect ref="iconSelect" @selected="selected" />
                                <el-input slot="reference" v-model="form.icon" placeholder="点击选择图标" readonly>
                                    <i v-if="form.icon" :class="form.icon" style="height: 32px; width: 16px"></i>
                                    <i v-else slot="prefix" class="el-icon-search el-input__icon" />
                                </el-input>
                            </el-popover> -->
                            <el-input v-model="form.icon"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="菜单名称" prop="menuName">
                            <el-input v-model="form.menuName" placeholder="请输入菜单名称" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="显示排序" prop="orderNum">
                            <el-input-number v-model="form.orderNum" controls-position="right" :min="0" />
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12" v-if="form.menuType != 'F'">
              <el-form-item prop="isFrame">
                <span slot="label">
                  <el-tooltip content="选择是外链则路由地址需要以`http(s)://`开头" placement="top">
                  <i class="el-icon-question"></i>
                  </el-tooltip>
                  是否外链
                </span>
                <el-radio-group v-model="form.isFrame">
                  <el-radio label="0">是</el-radio>
                  <el-radio label="1">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col> -->
                    <el-col :span="12" v-if="form.menuType != 'F'">
                        <el-form-item prop="path">
                            <span slot="label">
                                <el-tooltip content="访问的路由地址，如：`menu`，如外网地址需内链访问则以`http(s)://`开头" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                                路由地址
                            </span>
                            <el-input v-model="form.path" placeholder="请输入路由地址" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.menuType == 'C'">
                        <el-form-item prop="component">
                            <span slot="label">
                                <el-tooltip content="访问的组件路径，如：`system/menu/index`，默认在`views`目录下" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                                组件路径
                            </span>
                            <el-input v-model="form.component" placeholder="请输入组件路径" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.menuType != 'M'">
                        <el-form-item prop="perms">
                            <el-input v-model="form.perms" placeholder="请输入权限标识" maxlength="100" />
                            <span slot="label">
                                <el-tooltip content="控制器中定义的权限字符，如：@PreAuthorize(`@ss.hasPermi('system:menu:list')`)" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                                权限字符
                            </span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="form.menuType == 'C'">
                        <el-form-item prop="query">
                            <el-input v-model="form.query" placeholder="请输入路由参数" maxlength="255" />
                            <span slot="label">
                                <el-tooltip content='访问路由的默认传递参数，如：`{"id": 1, "name": "ry"}`' placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                                路由参数
                            </span>
                        </el-form-item>
                    </el-col>
                    <!-- <el-col :span="12" v-if="form.menuType == 'C'">
              <el-form-item prop="isCache">
                <span slot="label">
                  <el-tooltip content="选择是则会被`keep-alive`缓存，需要匹配组件的`name`和地址保持一致" placement="top">
                  <i class="el-icon-question"></i>
                  </el-tooltip>
                  是否缓存
                </span>
                <el-radio-group v-model="form.isCache">
                  <el-radio label="0">缓存</el-radio>
                  <el-radio label="1">不缓存</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col> -->
                    <!-- <el-col :span="12" v-if="form.menuType != 'F'">
              <el-form-item prop="visible">
                <span slot="label">
                  <el-tooltip content="选择隐藏则路由将不会出现在侧边栏，但仍然可以访问" placement="top">
                  <i class="el-icon-question"></i>
                  </el-tooltip>
                  显示状态
                </span>
                <el-radio-group v-model="form.visible">
                  <el-radio  key="0"  label="显示"></el-radio>
                  <el-radio  key="1"  label="隐藏"></el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col> -->
                    <el-col :span="12" v-if="form.menuType != 'F'">
                        <el-form-item prop="status">
                            <span slot="label">
                                <el-tooltip content="选择停用则路由将不会出现在侧边栏，也不能被访问" placement="top">
                                    <i class="el-icon-question"></i>
                                </el-tooltip>
                                菜单状态
                            </span>
                            <el-radio-group v-model="form.status">
                                <el-radio label="0">正常</el-radio>
                                <el-radio label="1">停用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
  
  <script>
//   import { listUser, getUser, delUser, addUser, updateUser, resetUserPwd, changeUserStatus, menuTreeSelect } from "@/api/system/menu";
import { getToken } from '@/utils/auth';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
// import IconSelect from "@/components/IconSelect";
export default {
    name: 'menuAdminHl',
    dicts: ['sys_normal_disable', 'sys_user_sex'],
    components: { Treeselect },
    data() {
        return {
            // 遮罩层
            loading: false,
            // 选中数组
            ids: [],
            // 非单个禁用
            single: true,
            // 重新渲染表格状态
            refreshTable: true,
            // 非多个禁用
            multiple: true,
            // 显示搜索条件
            showSearch: true,
            // 总条数
            total: 0,
            // 菜单表格数据
            menuList: null,
            // 弹出层标题
            title: '',
            // 部门树选项
            // menuOptions: undefined,
            // 是否显示弹出层
            open: false,
            // 岗位选项
            postOptions: [],
            // 菜单树选项
            menuOptions: [],
            // 菜单选项
            roleOptions: [],
            dictOption: [],
            // 是否展开，默认全部折叠
            isExpandAll: false,
            // 表单参数
            form: {},
            // 查询参数
            queryParams: {
                pageNo: 1,
                pageSize: 10,
                menuName: undefined,
                visible: undefined
            },
            // 表单校验
            rules: {
                menuName: [{ required: true, message: '菜单名称不能为空', trigger: 'blur' }],
                orderNum: [{ required: true, message: '菜单顺序不能为空', trigger: 'blur' }],
                path: [{ required: true, message: '路由地址不能为空', trigger: 'blur' }]
            },
            childrenList: [],
            expands: [],
            newMenuList: []
        };
    },
    watch: {},
    created() {
        this.getList();
        //   this.getConfigKey("sys.menu.initPassword").then(response => {
        //     this.initPassword = response.msg;
        //   });
    },
    methods: {
        // 选择图标
        selected(name) {
            this.form.icon = name;
        },
        /** 查询菜单列表 */
        getList() {
            this.loading = true;
            this.menuList = []; //  清空table的值
            // this.instance.get('/upms/api/menu/getMenuTree',{}).then(res=>{
            this.instance.get('/upms/api/menu/getFirstMenuList', {}).then((res) => {
                this.loading = false;
                this.menuList = res.data.data;
                let response = res.data.data;
                function addLabel(items) {
                    if (items) {
                        items.forEach((val) => {
                            val.label = val.menuName;
                            val.id = val.menuId;
                            if (val.children == null) {
                                delete val.children;
                            }
                            val.children && addLabel(val.children);
                        });
                    } else {
                        return false;
                    }
                }
                addLabel(response);
                this.menuOptions = response;
            });
        },
        async getChildrenList(menuId) {
            await this.instance.get('/upms/api/menu/getMenuListByParentId', { params: { menuId: menuId } }).then((res) => {
                this.childrenList = res.data.data;
            });
        },
        async cellClick(row, column) {
            if (column.label == '菜单名称' && !row.children) {
                let tmpIndex = this.menuList.findIndex((res) => {
                    return res.menuId == row.menuId;
                });
                await this.getChildrenList(row.menuId);
                row.children = this.childrenList;
                this.$set(this.menuList, tmpIndex, row);
                this.expands[0] = row.menuId;
            }
        },
        /** 转换菜单数据结构 */
        normalizer(node) {
            if (node.children && !node.children.length) {
                delete node.children;
            }
            return {
                id: node.menuId,
                label: node.menuName,
                children: node.children
            };
        },
        /** 查询菜单下拉树结构 */
        getTreeselect() {
            listMenu().then((response) => {
                this.menuOptions = [];
                const menu = { menuId: 0, menuName: '主类目', children: [] };
                menu.children = this.handleTree(response.data, 'menuId');
                this.menuOptions.push(menu);
            });
        },
        // 菜单状态修改
        handleStatusChange(row) {
            let text = row.status === '0' ? '启用' : '停用';
            this.$modal
                .confirm('确认要"' + text + '""' + row.userName + '"菜单吗？')
                .then(() => {
                    let json = {
                        userId: row.userId,
                        status: row.status
                    };
                    this.instance.post('/upms/api/menu/edit', json).then((res) => {});
                    this.$modal.msgSuccess(text + '成功');
                })
                .catch(function () {
                    row.status = row.status === '0' ? '1' : '0';
                });
        },
        /** 展开/折叠操作 */
        toggleExpandAll() {
            this.refreshTable = false;
            this.isExpandAll = !this.isExpandAll;
            this.$nextTick(() => {
                this.refreshTable = true;
            });
        },
        // 取消按钮
        cancel() {
            this.open = false;
            this.reset();
        },
        // 取消按钮（数据权限）
        cancelDataScope() {
            this.openDataScope = false;
            this.reset();
        },
        /** 选择菜单权限范围触发 */
        dataScopeSelectChange(value) {
            if (value !== '2') {
                this.$refs.menu.setCheckedKeys([]);
            }
        },
        /** 提交按钮（数据权限） */
        submitDataScope() {
            if (this.form.roleId != undefined) {
                this.form.menuIds = this.getDeptAllCheckedKeys();
                dataScope(this.form).then((response) => {
                    this.$modal.msgSuccess('修改成功');
                    this.openDataScope = false;
                    this.getList();
                });
            }
        },
        // 表单重置
        reset() {
            this.form = {
                menuId: undefined,
                parentId: 0,
                menuName: undefined,
                icon: undefined,
                menuType: 'M',
                orderNum: undefined,
                status: '0'
            };
            this.resetForm('form');
        },
        /** 搜索按钮操作 */
        handleQuery() {
            this.queryParams.pageNum = 1;
            this.getList();
        },
        /** 重置按钮操作 */
        resetQuery() {
            this.dateRange = [];
            this.resetForm('queryForm');
            this.queryParams.menuId = undefined;
            this.$refs.tree.setCurrentKey(null);
            this.handleQuery();
        },
        // 多选框选中数据
        handleSelectionChange(selection) {
            this.ids = selection.map((item) => item.userId);
            this.single = selection.length != 1;
            this.multiple = !selection.length;
        },
        // 更多操作触发
        handleCommand(command, row) {
            switch (command) {
                case 'handleResetPwd':
                    this.handleResetPwd(row);
                    break;
                case 'handleAuthRole':
                    this.handleAuthRole(row);
                    break;
                default:
                    break;
            }
        },
        /** 新增按钮操作 */
        handleAdd(row) {
            this.reset();
            this.open = true;
            // this.getTreeselect();
            if (row != null && row.menuId) {
                this.form.parentId = row.menuId;
            } else {
                this.form.parentId = 0;
            }
            this.title = '添加菜单';
            // getUser().then(response => {
            //   this.postOptions = response.posts;
            //   this.roleOptions = response.roles;
            //   this.open = true;
            //   this.title = "添加菜单";
            //   this.form.password = this.initPassword;
            // });
        },
        /** 修改按钮操作 */
        handleUpdate(row) {
            this.reset();
            console.log(row);
            this.form = row;
            this.open = true;
            this.title = '修改菜单';
            // this.instance.get('/upms/api/menu/getMenuListByParentId',{params:{menuId:row.menuId}}).then(res=>{
            //   if(res.data.code==200){
            //     this.form =res.data.data;
            //     this.open = true;
            //     this.title = "修改菜单";
            //   }else{
            //     this.$modal.msgError(res.data.message);
            //   }
            // });
        },
        /** 重置密码按钮操作 */
        handleResetPwd(row) {
            this.$prompt('请输入"' + row.userName + '"的新密码', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal: false,
                inputPattern: /^.{5,20}$/,
                inputErrorMessage: '菜单密码长度必须介于 5 和 20 之间'
            })
                .then(({ value }) => {
                    resetUserPwd(row.userId, value).then((response) => {
                        this.$modal.msgSuccess('修改成功，新密码是：' + value);
                    });
                })
                .catch(() => {});
        },
        /** 分配菜单操作 */
        handleAuthRole: function (row) {
            const userId = row.userId;
            this.$router.push('/system/menu-auth/role/' + userId);
        },
        /** 提交按钮 */
        submitForm() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (this.form.menuId != undefined) {
                        this.instance.post('/upms/api/menu/edit', this.form).then((res) => {
                            if (res.data.code == 200) {
                                this.$modal.msgSuccess('修改成功');
                                this.open = false;
                                this.getList();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        });
                    } else {
                        this.instance.post('/upms/api/menu/add', this.form).then((res) => {
                            if (res.data.code == 200) {
                                this.$modal.msgSuccess('新增成功');
                                this.open = false;
                                this.getList();
                            } else {
                                this.$modal.msgError(res.data.message);
                            }
                        });
                    }
                }
            });
        },
        /** 删除按钮操作 */
        handleDelete(row) {
            const userIds = row.userId || this.ids;
            this.$modal
                .confirm('是否确认删除菜单编号为"' + userIds + '"的数据项？')
                .then(function () {
                    return delUser(userIds);
                })
                .then(() => {
                    this.getList();
                    this.$modal.msgSuccess('删除成功');
                })
                .catch(() => {});
        },
        /** 导出按钮操作 */
        handleExport() {
            this.download(
                'system/menu/export',
                {
                    ...this.queryParams
                },
                `user_${new Date().getTime()}.xlsx`
            );
        },
        /** 导入按钮操作 */
        handleImport() {
            this.upload.title = '菜单导入';
            this.upload.open = true;
        },
        /** 下载模板操作 */
        importTemplate() {
            this.download('system/menu/importTemplate', {}, `user_template_${new Date().getTime()}.xlsx`);
        },
        // 文件上传中处理
        handleFileUploadProgress(event, file, fileList) {
            this.upload.isUploading = true;
        },
        // 文件上传成功处理
        handleFileSuccess(response, file, fileList) {
            this.upload.open = false;
            this.upload.isUploading = false;
            this.$refs.upload.clearFiles();
            this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + response.msg + '</div>', '导入结果', { dangerouslyUseHTMLString: true });
            this.getList();
        },
        // 提交上传文件
        submitFileForm() {
            this.$refs.upload.submit();
        }
    }
};
</script>