<template>
  <div class="wrap">
    <div class="page_title"><b>特征管理</b></div>
    <div class="main">
      <div class="table_box public_box">
        <!--table表格部分-->
        <el-table
            stripe
            ref="multipleTable"
            :data="tableData"
            v-loading="loading"
            style="width: 100%"
        >
          <!-- <el-table-column type="selection" width="55"></el-table-column> -->
          <el-table-column prop="featureCode" label="特征编码"></el-table-column>
          <el-table-column prop="featureName" label="特征名称" width="140px"></el-table-column>
          <el-table-column prop="featureDesc" label="特征描述"></el-table-column>
          <el-table-column prop="dataTypeName" label="特征数据类型"></el-table-column>
          <el-table-column prop="statusName" label="特征状态"></el-table-column>
          <el-table-column prop="fetchTypeName" label="特征数据获取方式"></el-table-column>
          <el-table-column prop="createTime" label="特征创建时间"></el-table-column>
        </el-table>
        <!--分页部分-->
        <el-pagination
            style="margin-top:0.2rem;"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
            :current-page="currentPage"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 20]"
            :page-size="10"
            :total="totalPage">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'caseList',
  components: {},
  props: {
    menuType: {
      type: String,
      default: '99'
    },
  },
  data() {
    return {
      loading: false,
      dialogVisible: false,
      dialogVisibleUser: false,
      currentPage: 1,      //当前页
      pageSize: 10,      //一页多少条数据
      totalPage: 1,         //总页数tableData:[],
      tableData: []
    }
  },
  methods: {
//获取tbody里的数据
    getTbodyData() {
      this.loading = true;
      this.tableData = [];//  清空table的值
      var json = JSON.parse("{}");
      json.pageNo = this.currentPage;
      json.pageSize = this.pageSize;
      this.instance.post('/risk/api/feature/definition/paging', json).then(res => {
// console.log(res);
        this.loading = false;
        this.tableData = res.data.data.list;
        this.totalPage = parseInt(res.data.data.total);
      });
    },
//分页数据多少条change
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTbodyData();
    },
//分页：当前页改变之后的函数
    handleCurrentChange(val) {
// console.log(val);
      this.currentPage = Number(val);
      this.getTbodyData();
    }
  },
  mounted() {  //
    this.getTbodyData();
  }
}
</script>