<template>
    <div class="app-container">
        <!--岗位数据-->
      <el-form :model="queryParams" ref="queryForm" size="small" :inline="true" v-show="showSearch" label-width="90px">
        <el-form-item label="岗位编码：" prop="postCode">
          <el-input
            v-model="queryParams.postCode"
            placeholder="请输入岗位编码"
            clearable
            class="width_170"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="岗位名称：" prop="postName">
          <el-input
            v-model="queryParams.postName"
            placeholder="请输入岗位名称"
            clearable
            class="width_170"
            @keyup.enter.native="handleQuery"
          />
        </el-form-item>
        <el-form-item label="状态：" prop="status">
          <el-select  v-model="queryParams.status"  placeholder="岗位状态"  clearable  class="width_170">
            <el-option  label="正常"  value="0"/>
            <el-option  label="停用"  value="1"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="mb8">
        <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
        <!-- <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete">删除</el-button> -->
      </div>
      <el-table v-loading="loading" :data="postList" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="50" align="center" />
        <el-table-column label="岗位编号" prop="postId"  />
        <el-table-column label="岗位编码" prop="postCode"  />
        <el-table-column label="岗位名称" prop="postName" :show-overflow-tooltip="true"  />
        <el-table-column label="岗位排序" prop="postSort"  />
        <el-table-column label="状态" align="center" key="status">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              active-value="0"
              inactive-value="1"
              @change="handleStatusChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" align="center" prop="createTime" width="160">
          <template slot-scope="scope">
            <span>{{ parseTime(scope.row.createTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          width="160"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope" v-if="scope.row.postId !== -1">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
            >修改</el-button>
            <!-- <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
            >删除</el-button> -->
            <!-- <el-dropdown size="mini" @command="(command) => handleCommand(command, scope.row)">
              <el-button size="mini" type="text" icon="el-icon-d-arrow-right">更多</el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="handleResetPwd" icon="el-icon-key"
                  >重置密码</el-dropdown-item>
                <el-dropdown-item command="handleAuthRole" icon="el-icon-circle-check"
                  >分配岗位</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown> -->
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNo"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
      />
  
      <!-- 添加或修改岗位配置对话框 -->
      <el-dialog :title="title" :visible.sync="open" width="500px" append-to-body>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label="岗位名称" prop="postName">
            <el-input v-model="form.postName" placeholder="请输入岗位名称" />
          </el-form-item>
          <el-form-item label="岗位编码" prop="postCode">
            <el-input v-model="form.postCode" placeholder="请输入编码名称" />
          </el-form-item>
          <el-form-item label="岗位顺序" prop="postSort">
            <el-input-number v-model="form.postSort" controls-position="right" :min="0" />
          </el-form-item>
          <el-form-item label="岗位状态" prop="status">
            <el-radio-group v-model="form.status">
              <el-radio label="0">正常</el-radio>
              <el-radio label="1">停用</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input v-model="form.remark" type="textarea" placeholder="请输入内容" />
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="cancel">取 消</el-button>
        </div>
      </el-dialog>
    </div>
  </template>
  
  <script>
//   import { listUser, getUser, delUser, addUser, updateUser, resetUserPwd, changeUserStatus, deptTreeSelect } from "@/api/system/post";
  import { getToken } from "@/utils/auth";
  
  export default {
    name: "postAdminHl",
    dicts: ['sys_normal_disable', 'sys_post_sex'],
    components: {  },
    data() {
      return {
        // 遮罩层  
        loading: false,
        // 选中数组
        ids: [],
        // 非单个禁用
        single: true,
        // 非多个禁用
        multiple: true,
        // 显示搜索条件
        showSearch: true,
        // 总条数
        total: 0,
        // 岗位表格数据
        postList: null,
        // 弹出层标题
        title: "",
        // 部门树选项
        deptOptions: undefined,
        // 是否显示弹出层
        open: false,
        // 部门名称
        deptName: undefined,
        // 默认密码
        initPassword: undefined,
        // 日期范围
        dateRange: [],
        // 岗位选项
        postOptions: [],
        // 岗位选项
        postOptions: [],
        dictOption:[],
        // 表单参数
        form: {},
        defaultProps: {
          children: "children",
          label: "label"
        },
        // 岗位导入参数
        upload: {
          // 是否显示弹出层（岗位导入）
          open: false,
          // 弹出层标题（岗位导入）
          title: "",
          // 是否禁用上传
          isUploading: false,
          // 是否更新已经存在的岗位数据
          updateSupport: 0,
          // 设置上传的请求头部
          headers: { Authorization: "Bearer " + getToken() },
          // 上传的地址
          url: process.env.VUE_APP_BASE_API + "/system/post/importData"
        },
        // 查询参数
        queryParams: {
          pageNo: 1,
          pageSize: 10,
          startCreateTime: '',
          endCreateTime: '',
          postCode: undefined,
          postName: undefined,
          status: undefined
        },
        // 表单校验
        rules: {
          postName: [
            { required: true, message: "岗位名称不能为空", trigger: "blur" }
          ],
          postCode: [
            { required: true, message: "岗位编码不能为空", trigger: "blur" }
          ],
          postSort: [
            { required: true, message: "岗位顺序不能为空", trigger: "blur" }
          ]
        }
      };
    },
    watch: {
    },
    created() {
      this.getList();
    //   this.getConfigKey("sys.post.initPassword").then(response => {
    //     this.initPassword = response.msg;
    //   });
    },
    methods: {
      /** 查询岗位列表 */
      getList() {
        this.loading=true;
        this.postList=[];//  清空table的值
        var queryParams=JSON.parse(JSON.stringify(this.queryParams));
        let json={
          condition:queryParams,
          pageNo:queryParams.pageNo,
          pageSize:queryParams.pageSize
        }
        this.instance.post('/upms/api/post/paging',json).then(res=>{
            // console.log(res);
            this.loading=false;
            this.postList=res.data.data.list;
            this.total=parseInt(res.data.data.total);
        });
      },
      // 岗位状态修改
      handleStatusChange(row) {
        let text = row.status === "0" ? "启用" : "停用";
        this.$modal.confirm('确认要"' + text + '""' + row.postName + '"岗位吗？').then(() => {
          let json={
            postId:row.postId,
            status:row.status
          }
          this.instance.post('/upms/api/post/edit',json).then(res=>{
            
          });
          this.$modal.msgSuccess(text + "成功");
        }).catch(function() {
          row.status = row.status === "0" ? "1" : "0";
        });
      },
      // 取消按钮
      cancel() {
        this.open = false;
        this.reset();
      },
       // 取消按钮（数据权限）
      cancelDataScope() {
        this.openDataScope = false;
        this.reset();
      },
       /** 选择岗位权限范围触发 */
      dataScopeSelectChange(value) {
        if(value !== '2') {
          this.$refs.dept.setCheckedKeys([]);
        }
      },
      /** 提交按钮（数据权限） */
      submitDataScope() {
        if (this.form.postId != undefined) {
          this.form.deptIds = this.getDeptAllCheckedKeys();
          dataScope(this.form).then(response => {
            this.$modal.msgSuccess("修改成功");
            this.openDataScope = false;
            this.getList();
          });
        }
      },
      // 表单重置
      reset() {
        this.form = {
          postId: undefined,
          postCode: undefined,
          postName: undefined,
          postSort: 0,
          status: "0",
          remark: undefined
        };
        this.resetForm("form");
      },
      /** 搜索按钮操作 */
      handleQuery() {
        
        this.queryParams.pageNum = 1;
        this.getList();
      },
      /** 重置按钮操作 */
      resetQuery() {
        this.dateRange = [];
        this.resetForm("queryForm");
        this.queryParams.deptId = undefined;
        this.$refs.tree.setCurrentKey(null);
        this.handleQuery();
      },
      // 多选框选中数据
      handleSelectionChange(selection) {
        this.ids = selection.map(item => item.postId);
        this.single = selection.length != 1;
        this.multiple = !selection.length;
      },
      // 更多操作触发
      handleCommand(command, row) {
        switch (command) {
          case "handleResetPwd":
            this.handleResetPwd(row);
            break;
          case "handleAuthRole":
            this.handleAuthRole(row);
            break;
          default:
            break;
        }
      },
      /** 新增按钮操作 */
      handleAdd() {
        this.reset();
        this.open = true;
        this.title = "添加岗位";
      },
      /** 修改按钮操作 */
      handleUpdate(row) {
        this.reset();
        this.form =row;
        this.open = true;
        this.title = "修改岗位";
        // this.instance.get('/upms/api/dept/getDeptById',{params:{postId:row.postId}}).then(res=>{
        //   if(res.data.code==200){
        //     this.form =res.data.data;
        //     this.open = true;
        //     this.title = "修改岗位";
        //   }else{
        //     this.$modal.msgError(res.data.message);
        //   }
        // });
      },
      /** 重置密码按钮操作 */
      handleResetPwd(row) {
        this.$prompt('请输入"' + row.postName + '"的新密码', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          inputPattern: /^.{5,20}$/,
          inputErrorMessage: "岗位密码长度必须介于 5 和 20 之间"
        }).then(({ value }) => {
            resetUserPwd(row.postId, value).then(response => {
              this.$modal.msgSuccess("修改成功，新密码是：" + value);
            });
          }).catch(() => {});
      },
      /** 分配岗位操作 */
      handleAuthRole: function(row) {
        const postId = row.postId;
        this.$router.push("/system/post-auth/post/" + postId);
      },
      /** 提交按钮 */
      submitForm() {
        this.$refs["form"].validate(valid => {
          if (valid) {
            if (this.form.postId != undefined) {
              this.instance.post('/upms/api/post/edit',this.form).then(res=>{
                if(res.data.code==200){
                  this.$modal.msgSuccess("修改成功");
                  this.open = false;
                  this.getList();
                }else{
                  this.$modal.msgError(res.data.message);
                }
              });
            } else {
              this.instance.post('/upms/api/post/add',this.form).then(res=>{
                if(res.data.code==200){
                  this.$modal.msgSuccess("新增成功");
                  this.open = false;
                  this.getList();
                }else{
                  this.$modal.msgError(res.data.message);
                }
              });
            }
          }
        });
      },
      /** 删除按钮操作 */
      handleDelete(row) {
        const postIds = row.postId || this.ids;
        this.$modal.confirm('是否确认删除岗位编号为"' + postIds + '"的数据项？').then(function() {
          return delUser(postIds);
        }).then(() => {
          this.getList();
          this.$modal.msgSuccess("删除成功");
        }).catch(() => {});
      },
      /** 导出按钮操作 */
      handleExport() {
        this.download('system/post/export', {
          ...this.queryParams
        }, `post_${new Date().getTime()}.xlsx`)
      },
      /** 导入按钮操作 */
      handleImport() {
        this.upload.title = "岗位导入";
        this.upload.open = true;
      },
      /** 下载模板操作 */
      importTemplate() {
        this.download('system/post/importTemplate', {
        }, `post_template_${new Date().getTime()}.xlsx`)
      },
      // 文件上传中处理
      handleFileUploadProgress(event, file, fileList) {
        this.upload.isUploading = true;
      },
      // 文件上传成功处理
      handleFileSuccess(response, file, fileList) {
        this.upload.open = false;
        this.upload.isUploading = false;
        this.$refs.upload.clearFiles();
        this.$alert("<div style='overflow: auto;overflow-x: hidden;max-height: 70vh;padding: 10px 20px 0;'>" + response.msg + "</div>", "导入结果", { dangerouslyUseHTMLString: true });
        this.getList();
      },
      // 提交上传文件
      submitFileForm() {
        this.$refs.upload.submit();
      }
    }
  };
  </script>